import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useQuery } from "../../hook/useQuery";
import axios from "axios";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import useCheckInet from "../../hook/useCheckInet";
import StatusInet from "../../components/status_inet";
import moment from "moment/moment";
import useUser from "../../hook/useUser";
import useReport from "../../hook/useReport";
import PopupSearch from "../../components/popup_search";

const PageCheckin = (props) => {
  const localReport = useReport();
  const checkInet = useCheckInet();
  const localUser = useUser();
  const query = useQuery();
  const [state, setState] = useState("idle");
  const [showQR, setShowQR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoAction, setInfoAction] = useState(false);
  const [user, setUser] = useState(null);
  const [sukses, setSukses] = useState(false);
  const [showSearch, setShowSearch] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    // if (checkInet.isOnline) {
    //   localReport.fetchData();
    // }

    localUser.fetchData();
    // getReport();
  }, [checkInet.isOnline]);

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/");
    }
  }, []);

  const handleSuccess = (qr) => {
    // if (checkInet.isOnline) {
    //   checkDataUser(qr);
    // } else {
    //   checkDataUserOffline(qr);
    // }

    checkDataUser(qr);
  };

  const handleFailed = () => {
    setShowQR(false);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("failed");
  };

  const handleCloseScan = () => {
    setShowQR(false);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("idle");
  };

  const handleGoHome = () => {
    navigate("/home-2");
  };

  const handleOpenScan = () => {
    setShowQR(true);
    setShowSearch(false);
    setInfoAction(false);
    setUser(null);
    setState("process");
  };

  // OFFLINE MODE
  const checkDataUserOffline = (qr) => {
    setShowQR(false);
    setShowSearch(false);
    setLoading(true);
    let users = window.localStorage.getItem("LocalUser");
    users = JSON.parse(users);

    let data = users.find((v) => v.qr_code === qr);
    if (data) {
      let event = data.event.find((item) => item.gate === query.get("event"));
      if (event) {
        setUser(data);

        if (event.checkin_time === "0000-00-00 00:00:00") {
          setState("success");
        } else {
          // if (event.checkout_time === "0000-00-00 00:00:00") {
          //   setState("exist");
          // } else {
          //   setState("reenter");
          // }

          setState("reenter");
        }
      } else {
        setState("failed");
      }

      setLoading(false);
    } else {
      setLoading(false);
      setState("failed");
    }
  };

  const handleCheckinOffline = () => {
    setSukses(false);
    setState("process");
    setLoading(true);

    let arr = [];
    const payload = {
      qr_code: user?.qr_code,
      id_gate: query.get("id_gate"),
      checkin_time: moment(Date.now()).format("YYYY-MM-DD hh:mm:ss"),
    };

    let checkin = window.localStorage.getItem("LocalCheckin");
    if (checkin) {
      checkin = JSON.parse(checkin);
      arr = [...checkin];

      let exist = arr.find((item) => item.qr_code === payload.qr_code);
      if (!exist) {
        arr.push(payload);

        //update local user
        let users = window.localStorage.getItem("LocalUser");
        users = JSON.parse(users);
        let _users = [...users];
        let _user = _users.find((v) => v.qr_code === payload.qr_code);
        let _event = _user.event.find((v) => v.gate === query.get("event"));
        _event.checkin_time = payload.checkin_time;

        window.localStorage.setItem("LocalUser", JSON.stringify(_users));
        //update local user
      }
    } else {
      arr.push(payload);

      //update local user
      let users = window.localStorage.getItem("LocalUser");
      users = JSON.parse(users);
      let _users = [...users];
      let _user = _users.find((v) => v.qr_code === payload.qr_code);
      let _event = _user.event.find((v) => v.gate === query.get("event"));
      _event.checkin_time = payload.checkin_time;

      window.localStorage.setItem("LocalUser", JSON.stringify(_users));
      //update local user
    }

    window.localStorage.setItem("LocalCheckin", JSON.stringify(arr));
    props.checkLocalCheckin();

    setInfoAction(true);
    setLoading(false);
    setSukses(true);
  };

  // OFFLINE MODE

  const handleCheckin = () => {
    // if (checkInet.isOnline) {
    //   handleCheckinOnline();
    // } else {
    //   handleCheckinOffline();
    // }

    handleCheckinOnline();
  };

  const checkDataUser = (qr) => {
    setShowQR(false);
    setShowSearch(false);
    setLoading(true);
    axios
      .get(CONFIG.URL + "/user/" + qr, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setUser(response.data?.data[0]);
          setState("success");
          // if (event) {

          //   if (event.checkin_time === "0000-00-00 00:00:00") {
          //     setState("success");
          //   } else {
          //     // if (event.checkout_time === "0000-00-00 00:00:00") {
          //     //   setState("exist");
          //     // } else {
          //     //   setState("reenter");
          //     // }

          //     setState("reenter");
          //   }
          // } else {
          //   setState("failed");
          // }
        } else {
          setState("failed");
        }
        setLoading(false);
      })
      .catch(function (error) {
        handleCloseScan();
        setLoading(false);
      });
  };

  const handleCheckinOnline = () => {
    setSukses(false);
    setState("process");
    setLoading(true);

    const payload = {
      id_user: user?.id,
      day: 2,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/checkin", form_data, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        setInfoAction(true);
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          setSukses(true);
          //localUser.fetchData();

          setTimeout(() => {
            handleGoHome();
          }, 1000);

          // localReport.fetchData();
        } else {
          setSukses(false);
        }
      })
      .catch(function (error) {
        setLoading("false");
        setSukses(false);
      });
  };

  const handleActionFailed = () => {
    setState("success");
    setInfoAction(false);
  };

  const getReport = () => {
    if (checkInet.isOnline) {
      try {
        let _event = localReport?.data?.find(
          (v) => v.gate === query.get("event")
        );
        return _event.checkin;
      } catch (error) {
        return "-";
      }
    } else {
      let _localReport = window.localStorage.getItem("LocalReport");
      _localReport = JSON.parse(_localReport);
      try {
        let _event = _localReport?.find((v) => v.gate === query.get("event"));
        return _event.checkin;
      } catch (error) {
        return "-";
      }
    }
  };

  const handleSuccessSearch = (id) => {
    // if (checkInet.isOnline) {
    //   checkDataUser(id);
    // } else {
    //   checkDataUserOffline(id);
    // }

    checkDataUser(id);
  };

  const handleCloseSearch = () => {
    setShowSearch(false);
    setUser(null);
    setState("idle");
  };

  const handleOpenSearch = () => {
    setShowSearch(true);
    setInfoAction(false);
    setUser(null);
    setState("process");
  };

  return (
    <>
      {loading && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
            Loading...
          </div>
        </>
      )}

      {showSearch && (
        <PopupSearch
          onClose={() => handleGoHome()}
          onSuccess={(data) => handleSuccessSearch(data)}
          onFailed={() => handleFailed()}
          gate={`CHECK IN`}
          users={JSON.parse(window.localStorage.getItem("LocalUser"))}
        />
      )}

      {infoAction && (
        <>
          <div className="flex flex-col absolute top-0 left-0 w-screen h-screen bg-[#000000b3] text-white justify-center items-center font-semibold text-xl">
            {sukses ? (
              <>
                <span className="text-green-500 font-bold">
                  Check In Success
                </span>
                <button
                  onClick={() => handleGoHome()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-green-500 rounded-lg text-white font-bold"
                >
                  OK
                </button>
              </>
            ) : (
              <>
                <span className="text-red-500 font-bold">Check In Failed</span>
                <button
                  onClick={() => handleActionFailed()}
                  className="mt-5 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
                >
                  OK
                </button>
              </>
            )}
          </div>
        </>
      )}

      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK IN"
          event={""}
        />
      )}

      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-black px-0 bg-cover bg-no-repeat"
        style={{
          background: `url("${props?.setting?.background}")`,
        }}
      >
        {/* {!loading && state !== "process" && (
          <div className="flex flex-col justify-center items-center mb-10">
            <div className="text-white font-semibold mb-2">CHECK IN</div>
            <div className="text-white font-bold mb-4 text-x uppercase">
              {query.get("event")}
            </div>
            <div className="text-white font-semibold text-sm">
              Currently Checked In: {getReport()}
            </div>
          </div>
        )} */}

        {state === "idle" && (
          <div className="flex flex-col justify-center items-center pb-20">
            {/* <button
              onClick={() => handleOpenScan()}
              className="min-w-[160px] px-10 py-2 bg-[#051f5b] rounded-lg text-white font-bold"
            >
              SCAN
            </button>
            <button
              onClick={() => handleOpenSearch()}
              className="mt-4 min-w-[160px] px-10 py-2 bg-[#051f5b] rounded-lg text-white font-bold"
            >
              SEARCH
            </button>
            <a
              href={`/home-2`}
              className="min-w-[160px] mt-4 px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
            >
              GO HOME
            </a> */}
          </div>
        )}

        {state === "success" && (
          <div className="flex flex-col justify-center items-center pb-20 w-full">
            <div className="text-white font-bold mb-6 text-xl bg-green-500 px-10 py-12 w-full text-center">
              Allowed to Enter
            </div>

            <div className="text-white flex flex-col font-bold mb-6 text-2xl text-center">
              <span className="text-2xl"> {user?.name}</span>
              <span className="text-base text-gray-300">{user?.email}</span>
            </div>

            <button
              onClick={() => handleCheckin()}
              className="min-w-[160px]  px-10 py-2 bg-[#051f5b] rounded-lg text-white font-bold"
            >
              CHECK IN
            </button>

            <button
              onClick={() => handleOpenSearch()}
              className="mt-20 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
            >
              CANCEL
            </button>
          </div>
        )}

        {state === "reenter" && (
          <div className="flex flex-col justify-center items-center pb-20 w-full">
            <div className="text-white font-bold mb-6 text-xl bg-green-500 px-10 py-12 w-full text-center">
              Allowed to Reenter
            </div>

            <div className="text-white font-bold mb-0 text-lg text-center">
              {user?.user_type}
            </div>

            <div className="text-white flex flex-col font-bold mb-6 text-2xl text-center">
              <span className="text-2xl"> {user?.name}</span>
              <span className="text-base text-gray-300">{user?.team}</span>
              <span className="text-base text-gray-300">
                {user?.job_title} - {user?.company}
              </span>
            </div>

            <button
              onClick={() => handleCheckin()}
              className="min-w-[160px]  px-10 py-2 bg-[#051f5b] rounded-lg text-white font-bold"
            >
              CHECK IN
            </button>

            <button
              onClick={() => handleCloseScan()}
              className="mt-20 min-w-[160px] px-10 py-2 bg-red-500 rounded-lg text-white font-bold"
            >
              CANCEL
            </button>
          </div>
        )}

        {state === "failed" && (
          <div className="flex flex-col justify-center items-center pb-20 w-full">
            <div className="text-white font-bold mb-10 text-xl bg-red-500 px-10 py-12 w-full text-center">
              Not Allowed to Enter
            </div>

            <div className="text-white font-bold mb-0 text-lg text-center">
              {user?.user_type}
            </div>
            <div className="text-white flex flex-col font-bold mb-6 text-2xl text-center">
              <span className="text-2xl"> {user?.name}</span>
              <span className="text-base text-gray-300">{user?.team}</span>
              <span className="text-base text-gray-300">
                {user?.job_title} - {user?.company}
              </span>
            </div>

            <button
              onClick={() => handleCloseScan()}
              className="min-w-[160px] px-10 py-2 bg-[#051f5b] rounded-lg text-white font-bold"
            >
              OK
            </button>
          </div>
        )}

        {state === "exist" && (
          <div className="flex flex-col justify-center items-center pb-20 w-full">
            <div className="text-white font-bold mb-10 text-xl bg-red-500 px-10 py-12 w-full text-center">
              Already Check In
            </div>

            <div className="text-white font-bold mb-0 text-lg text-center">
              {user?.user_type}
            </div>
            <div className="text-white flex flex-col font-bold mb-6 text-2xl text-center">
              <span className="text-2xl"> {user?.name}</span>
              <span className="text-base text-gray-300">{user?.team}</span>
              <span className="text-base text-gray-300">
                {user?.job_title} - {user?.company}
              </span>
            </div>

            <button
              onClick={() => handleCloseScan()}
              className="min-w-[160px] px-10 py-2 bg-[#051f5b] rounded-lg text-white font-bold"
            >
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PageCheckin;
