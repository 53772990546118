import axios from "axios";
import { useEffect, useState } from "react";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";

const FormSession = (props) => {
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data !== null) {
      setData(props?.data);
    }
  }, [props?.data]);

  useEffect(() => {
    if (props?.listData !== null && props?.listData?.length > 0) {
      setListData(props?.listData);
    }
  }, [props?.listData]);

  const chooseData = (item) => {
    let arr = [...data];
    if (arr.length < 3) {
      let idxExist = arr.findIndex((v) => v.id_session === item.id_session);

      if (idxExist !== -1) {
        arr.splice(idxExist, 1);
      } else {
        arr.push(item);
      }

      setData(arr);
      props.setData(arr);
    } else {
      let idxExist = arr.findIndex((v) => v.id_session === item.id_session);

      if (idxExist !== -1) {
        arr.splice(idxExist, 1);
        setData(arr);
        props.setData(arr);
      } else {
        alert("Maximum 3 Selections");
      }
    }
  };

  const handleSetData = () => {
    if (data !== null && data.length > 0) props?.next();
  };

  const checkActive = (item) => {
    let active = data?.find((v) => v.id_session === item.id_session);

    if (active) return "bg-[#fb4516]";
    else return "bg-[#051f5b]";
  };

  return (
    <div className="space-y-5 px-5 py-5 bg-white rounded-md w-full">
      <div className="text-center font-bold mb-5">REGISTRATION</div>

      <div>
        <div className="flex flex-col">
          <label className="text-black w-full mb-5 text-2xl font-bold text-center">
            Sessions of Interest (Maximum 3 Selections) :
          </label>
        </div>

        <div className="grid grid-cols-2 gap-2">
          {listData?.map((item, key) => {
            return (
              <button
                key={key}
                onClick={() => chooseData(item)}
                type="button"
                className={`
              ${checkActive(item)}
              min-w-[160px] px-10 py-2 rounded-lg text-white font-bold  hover:bg-[#fb4516]  w-full text-center last:col-span-2`}
              >
                {item.session}
              </button>
            );
          })}
        </div>
      </div>

      <div className="flex space-x-5 justify-between">
        <button
          onClick={() => props?.back()}
          type="button"
          className={`max-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
        >
          BACK
        </button>
        <button
          onClick={() => handleSetData()}
          type="button"
          className={`
          ${data !== null && data?.length > 0 ? "bg-green-500" : "bg-gray-500"}
          max-w-[160px] px-10 py-2 rounded-lg text-white font-bold   w-full text-center`}
        >
          NEXT
        </button>
      </div>
    </div>
  );
};

export default FormSession;
