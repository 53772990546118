import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";

const FormBiodata = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props?.data !== null) {
      setValue("name", props?.data?.name);
      setValue("email", props?.data?.email);
    }
  }, [props?.data, setValue]);

  const onSubmit = (data) => {
    props.setData({
      name: data.name,
      email: data.email,
    });

    props.next();
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  return (
    <form
    autoComplete="off"
    role="presentation"
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-5 px-5 py-5 bg-white rounded-md w-full"
    >
      <div className="text-center font-bold mb-5">REGISTRATION</div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Full Name:</label>
        <input
        autoFocus
        autoComplete="off"
        role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("name", { required: true })}
        />
        {errors.name && (
          <span className="text-red-500 text-sm">This field is required</span>
        )}
      </div>

      <div className="flex flex-col">
        <label className="text-black w-full font-bold">Email Address:</label>
        <input
        autoComplete="off"
        role="presentation"
          className="border-2 px-2 py-2 rounded-sm"
          {...register("email", {
            required: true,
            validate: handleEmailValidation,
          })}
        />
        {errors.email && (
          <>
            {errors.email?.type === "validate" ? (
              <span className="text-red-500 text-sm">Email not Valid</span>
            ) : (
              <span className="text-red-500 text-sm">
                This field is required
              </span>
            )}
          </>
        )}
      </div>

      <div className="flex space-x-5 justify-between">
        <a href={props?.day === 1 ? "/home-1" : "/home-2"}>
          <button
            type="button"
            className={`max-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            BACK
          </button>
        </a>
        <button
          type="submit"
          className={`max-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
        >
          NEXT
        </button>
      </div>
    </form>
  );
};

export default FormBiodata;
