import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import FormBiodata from "./formBiodata";
import FormAgeRange from "./formAgeRange";
import FormJobTitle from "./formJobTitle";
import FormSession from "./formSession";
import FormHow from "./formHow";
import FormReason from "./formReason";
import FormSummary from "./formSummary";

const PageRegistration = (props) => {
  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/");
    }

    getListAgeRange();
    getListJob();
    getListSession();
    getListHow();
    getListReason();
  }, []);

  const [step, setStep] = useState("biodata");
  // state:
  // - biodata
  // - age_range
  // - job_title
  // - session
  // - how
  // - reason
  // - summary

  const navigate = useNavigate();
  const [postData, setPostData] = useState({
    biodata: null,
    age_range: null,
    job_title: null,
    session: null,
    how: null,
    reason: null,
  });

  const [listAge, setListAge] = useState([]);
  const getListAgeRange = () => {
    axios
      .get(CONFIG.URL + "/event/age", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          let ls = response?.data?.data?.filter((item) => item.id_age !== '1');
          setListAge(ls);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const [listJob, setListJob] = useState([]);
  const getListJob = () => {
    axios
      .get(CONFIG.URL + "/event/job", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setListJob(response?.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const [listSession, setListSession] = useState([]);
  const getListSession = () => {
    axios
      .get(CONFIG.URL + "/event/session", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setListSession(response?.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const [listHow, setListHow] = useState([]);
  const getListHow = () => {
    axios
      .get(CONFIG.URL + "/event/how", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setListHow(response?.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const [listReason, setListReason] = useState([]);
  const getListReason = () => {
    axios
      .get(CONFIG.URL + "/event/reason", {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setListReason(response?.data?.data);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const setBiodata = (data) => {
    setPostData({
      ...postData,
      biodata: data,
    });
  };

  const setAgeRange = (data) => {
    setPostData({
      ...postData,
      age_range: data,
    });
  };

  const setJobTitle = (data) => {
    setPostData({
      ...postData,
      job_title: data,
    });
  };

  const setSession = (data) => {
    setPostData({
      ...postData,
      session: data,
    });
  };

  const setHow = (data) => {
    setPostData({
      ...postData,
      how: data,
    });
  };

  const setReason = (data) => {
    setPostData({
      ...postData,
      reason: data,
    });
  };

  const handleReset = () => {
    setStep("biodata");
    setPostData({
      biodata: null,
      age_range: null,
      job_title: null,
      session: null,
      how: null,
      reason: null,
    });
  };

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-black px-5 bg-cover bg-no-repeat"
        style={{
          background: `url("${props?.setting?.background}")`,
        }}
      >
        <div className="flex flex-col justify-center items-center pb-20 w-full md:w-2/3">
          {/* <div className="text-white mb-10">{JSON.stringify(postData)}</div> */}

          {/* FORM BIODATA */}
          {step === "biodata" && (
            <FormBiodata
              day={props?.day}
              setData={(data) => setBiodata(data)}
              data={postData?.biodata}
              next={() => setStep("age_range")}
            />
          )}

          {/* FORM AGE RANGE */}
          {step === "age_range" && (
            <FormAgeRange
              setData={(data) => setAgeRange(data)}
              data={postData?.age_range}
              listData={listAge}
              back={() => setStep("biodata")}
              next={() => setStep("job_title")}
            />
          )}

          {/* FORM JOB TITLE */}
          {step === "job_title" && (
            <FormJobTitle
              setData={(data) => setJobTitle(data)}
              data={postData?.job_title}
              listData={listJob}
              back={() => setStep("age_range")}
              next={() => setStep("session")}
            />
          )}

          {/* FORM SESSION */}
          {step === "session" && (
            <FormSession
              setData={(data) => setSession(data)}
              data={postData?.session}
              listData={listSession}
              back={() => setStep("job_title")}
              next={() => setStep("how")}
            />
          )}

          {/* FORM HOW */}
          {step === "how" && (
            <FormHow
              setData={(data) => setHow(data)}
              data={postData?.how}
              listData={listHow}
              back={() => setStep("session")}
              next={() => setStep("reason")}
            />
          )}

          {/* FORM REASON */}
          {step === "reason" && (
            <FormReason
              setData={(data) => setReason(data)}
              data={postData?.reason}
              listData={listReason}
              back={() => setStep("how")}
              next={() => setStep("summary")}
            />
          )}

          {/* FORM SUMMARY */}
          {step === "summary" && (
            <FormSummary
              day={props?.day}
              data={postData}
              back={() => setStep("reason")}
              ok={() => handleReset()}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PageRegistration;
