import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import PageHome from "./pages/home";
import PageCheckin from "./pages/checkin";
import PageRedeem from "./pages/redeem";
import PageCheckout from "./pages/checkout";
import PageLogin from "./pages/login";
import PageEvent from "./pages/event";
import StatusInet from "./components/status_inet";
import PageRegistration from "./pages/registration";
import PageDay1 from "./pages/home/day1";
import PageDay2 from "./pages/home/day2";
import axios from "axios";
import CONFIG from "./constanta/config";
import Cookies from "js-cookie";

function App() {
  const [totalCheckin, setTotalCheckin] = useState(0);
  const [totalCheckout, setTotalCheckout] = useState(0);
  const [totalRedeem, setTotalRedeem] = useState(0);
  const [setting, setSetting] = useState(null);

  useEffect(() => {
    // checkLocal();
    getListSetting();
  }, []);

  const getListSetting = () => {
    axios
      .get(CONFIG.URL + "/config")
      .then(function (response) {
        if (response?.data.status === "SUCCESS") {
          setSetting(response?.data?.config);
        } else {
        }
      })
      .catch(function (error) {});
  };

  const checkLocalCheckin = () => {
    let checkin = window.localStorage.getItem("LocalCheckin");
    if (checkin) {
      setTotalCheckin(JSON.parse(checkin).length);
    } else {
      setTotalCheckin(0);
    }
  };

  const checkLocalCheckout = () => {
    let checkout = window.localStorage.getItem("LocalCheckout");
    if (checkout) {
      setTotalCheckout(JSON.parse(checkout).length);
    } else {
      setTotalCheckout(0);
    }
  };

  const checkLocalRedeem = () => {
    let redeem = window.localStorage.getItem("LocalRedeem");
    if (redeem) {
      setTotalRedeem(JSON.parse(redeem).length);
    } else {
      setTotalRedeem(0);
    }
  };

  const checkLocal = () => {
    checkLocalCheckin();
    checkLocalCheckout();
    checkLocalRedeem();
  };

  return (
    <>
      {/* <StatusInet
        totalCheckin={totalCheckin}
        totalCheckout={totalCheckout}
        totalRedeem={totalRedeem}
        checkLocal={() => checkLocal()}
      />
      <div className="pt-8"></div> */}

      <div className="overflow-y-auto">
        <Routes>
          <Route path="/" element={<PageLogin setting={setting} />} />
          <Route path="/home" element={<PageHome setting={setting} />} />
          <Route path="/home-1" element={<PageDay1 setting={setting} />} />
          <Route path="/home-2" element={<PageDay2 setting={setting} />} />
          <Route
            path="/day-one"
            element={<PageRegistration setting={setting} day={1} />}
          />
          <Route
            path="/day-two"
            element={<PageRegistration setting={setting} day={2} />}
          />
          <Route
            path="/checkin"
            element={
              <PageCheckin
                setting={setting}
                checkLocalCheckin={() => checkLocalCheckin()}
              />
            }
          />

          {/* <Route path="/event" element={<PageEvent />} />
          
          
          {/* <Route
            path="/checkout"
            element={
              <PageCheckout checkLocalCheckout={() => checkLocalCheckout()} />
            }
          /> */}
          {/* <Route
            path="/redeem"
            element={<PageRedeem checkLocalRedeem={() => checkLocalRedeem()} />}
          /> */}
        </Routes>
      </div>
    </>
  );
}

export default App;
