import axios from "axios";
import { useEffect, useRef, useState } from "react";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";

const FormReason = (props) => {
  const [listData, setListData] = useState([]);
  const [data, setData] = useState(null);
  const inputOther = useRef(null);

  useEffect(() => {
    if (props?.data !== null) {
      setData(props?.data);
    }
  }, [props?.data]);

  useEffect(() => {
    if(inputOther?.current){
      inputOther?.current?.focus();
    }
  }, [data, inputOther])

  useEffect(() => {
    if (props?.data !== null && inputOther?.current !== null) {
      if (props?.data?.other !== "") {
        inputOther.current.value = props?.data?.other;
      }
    }
  }, [props?.data, data, inputOther]);

  useEffect(() => {
    if (props?.listData !== null && props?.listData?.length > 0) {
      setListData(props?.listData);
    }
  }, [props?.listData]);

  const chooseData = (item) => {
    if (item.reason.toLowerCase() === "other") {
      let _item = {
        ...item,
        other: "",
      };
      setData(_item);
      props.setData(_item);
    } else {
      setData(item);
      props.setData(item);
    }
  };

  const chooseDataOther = (e) => {
    let _data = {
      ...data,
      other: e.currentTarget.value,
    };
    setData(_data);
    props.setData(_data);
  };

  const handleSetData = () => {
    if (data !== null) props?.next();
  };

  return (
    <div className="space-y-5 px-5 py-5 bg-white rounded-md w-full">
      <div className="text-center font-bold mb-5">REGISTRATION</div>

      <div>
        <div className="flex flex-col">
          <label className="text-black w-full mb-5 text-2xl font-bold text-center">
            Reason for attending:
          </label>
        </div>

        <div className="grid grid-cols-2 gap-2">
          {listData?.map((item, key) => {
            if (item.reason.toLowerCase() !== "other") {
              return (
                <button
                  key={key}
                  onClick={() => chooseData(item)}
                  type="button"
                  className={`
              ${
                item?.id_reason === data?.id_reason
                  ? "bg-[#fb4516]" : "bg-[#051f5b]"
              }
              min-w-[160px] px-10 py-2 rounded-lg text-white font-bold  hover:bg-[#fb4516]  w-full text-center
              last:col-span-2`}
                >
                  {item.reason}
                </button>
              );
            }
          })}
        </div>

        <div className="grid grid-cols-1 gap-2 pt-3 border-t mt-3">
          {listData?.map((item, key) => {
            if (item.reason.toLowerCase() === "other") {
              return (
                <button
                  key={key}
                  onClick={() => chooseData(item)}
                  type="button"
                  className={`
              ${
                item?.id_reason === data?.id_reason
                  ? "bg-[#fb4516]" : "bg-[#051f5b]"
              }
              min-w-[160px] px-10 py-2 rounded-lg text-white font-bold  hover:bg-[#fb4516]  w-full text-center
              last:col-span-2`}
                >
                  {item.reason}
                </button>
              );
            }
          })}

          {data?.reason?.toLowerCase() === "other" && (
            <div className="flex flex-col">
              <input
              autoComplete="off"
              role="presentation"
                ref={inputOther}
                className="border-2 px-2 py-2 rounded-sm"
                onChange={chooseDataOther}
              />
              {/* <span className="text-red-500 text-sm">
                This field is required
              </span> */}
            </div>
          )}
        </div>
      </div>

      <div className="flex space-x-5 justify-between">
        <button
          onClick={() => props?.back()}
          type="button"
          className={`max-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
        >
          BACK
        </button>
        <button
          onClick={() => handleSetData()}
          type="button"
          className={`
          ${data !== null ? "bg-green-500" : "bg-gray-500"}
          max-w-[160px] px-10 py-2 rounded-lg text-white font-bold   w-full text-center`}
        >
          NEXT
        </button>
      </div>
    </div>
  );
};

export default FormReason;
