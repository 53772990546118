import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../hook/useQuery";
import Cookies from "js-cookie";

const PageDay2 = (props) => {
  const query = useQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token") === undefined) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div
        className="w-full h-screen flex flex-col justify-center items-center bg-black px-5 bg-cover bg-no-repeat"
        style={{
          background: `url("${props?.setting?.background}")`,
        }}
      >
        <div className="flex flex-col justify-center items-center pb-20">
          <div className="text-white font-bold mb-10 capitalize text-center">
            <div className="text-2xl ">Welcome to Big Data Forum TT</div>
            <div>Conference Day 2</div>
          </div>
          <div className="mb-10 flex flex-col justify-center items-center space-y-2.5 w-full">
            <a
              href={`/day-two`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              NEW REGISTRATION
            </a>

            <a
              href={`/checkin`}
              className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-blue-500 w-full text-center`}
            >
              ALREADY REGISTERED ON DAY 1
            </a>
          </div>

          <div className="mb-10 flex flex-col justify-center items-center space-y-2.5 w-full">
            <a
              href="/home"
              className="min-w-[160px] mt-1 px-10 py-2 bg-red-500 rounded-lg text-white font-bold w-full text-center"
            >
              BACK
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageDay2;
