import axios from "axios";
import { useEffect, useState } from "react";
import CONFIG from "../../constanta/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const FormSummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [sukses, setSukses] = useState(null);
  const navigate = useNavigate();

  const submitPostData = () => {
    if (loading) return;

    setMsg("");
    setLoading(true);
    const payload = {
      day: props?.day,
      name: props?.data?.biodata?.name,
      email: props?.data?.biodata?.email,
      id_age: props?.data?.age_range?.id_age,
      id_session: props?.data?.session?.map((v) => v.id_session).join(","),
      id_job: props?.data?.job_title?.id_job,
      id_how: props?.data?.how?.id_how,
      id_reason: props?.data?.reason?.id_reason,
      other_job:
        props?.data?.job_title?.job?.toLowerCase() === "other"
          ? props?.data?.job_title?.other
          : "",
      other_how:
        props?.data?.how?.how?.toLowerCase() === "other"
          ? props?.data?.how?.other
          : "",
      other_reason:
        props?.data?.reason?.reason?.toLowerCase() === "other"
          ? props?.data?.reason?.other
          : "",
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/event/user", form_data, {
        headers: {
          token: Cookies.get("token"),
        },
      })
      .then(function (response) {
        setLoading(false);
        if (response?.data.status === "SUCCESS") {
          setSukses(true);
          setTimeout(() => {
            if(props?.day === 1){
              navigate("/home-1");
            }
            else{
              navigate("/home-2");
            }
          }, 1000);
        } else {
          setSukses(false);
          setMsg(response?.data?.message);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setMsg("Something went wrong, please try again");
      });
  };

  const handleBack = () => {
    setSukses(null);
    setLoading(false);
    setMsg("");
    props?.back();
  };

  return (
    <div className="space-y-5 px-5 py-5 bg-white rounded-md w-full">
      <div className="text-center font-bold mb-5">REGISTRATION</div>

      <div>
        <div className="flex flex-col">
          <label className="text-black w-full mb-5 text-2xl font-bold text-center">Summary:</label>
        </div>

        <div className="flex flex-col justify-center items-center text-center space-y-1">
          <div className="border-b pb-2 w-full">
            <div>Full Name :</div>
            <div className="font-bold">{props?.data?.biodata?.name}</div>
          </div>
          <div className="border-b pb-2 w-full">
            <div>Email :</div>
            <div className="font-bold">{props?.data?.biodata?.email}</div>
          </div>
          <div className="border-b pb-2 w-full">
            <div>Age Range :</div>
            <div className="font-bold">{props?.data?.age_range?.age}</div>
          </div>
          <div className="border-b pb-2 w-full">
            <div>Job Title :</div>
            <div className="font-bold">
              {props?.data?.job_title?.job?.toLowerCase() === "other"
                ? props?.data?.job_title?.other
                : props?.data?.job_title?.job}
            </div>
          </div>
          <div className="border-b pb-2 w-full">
            <div>Sessions of Interest :</div>
            {props?.data?.session?.map((item, key) => {
              return (
                <div className="flex space-x-2 justify-center items-center">
                  <span className="flex-none w-[10px] font-bold">
                    {key + 1}.
                  </span>
                  <div key={key} className="font-bold">
                    {item.session}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="border-b pb-2 w-full">
            <div>How did you hear about Big Data Forum? :</div>
            <div className="font-bold">
              {props?.data?.how?.how?.toLowerCase() === "other"
                ? props?.data?.how?.other
                : props?.data?.how?.how}
            </div>
          </div>
          <div className="border-b pb-2 w-full">
            <div>Reason :</div>
            <div className="font-bold">
              {" "}
              {props?.data?.reason?.reason?.toLowerCase() === "other"
                ? props?.data?.reason?.other
                : props?.data?.reason?.reason}
            </div>
          </div>
        </div>
      </div>

      {sukses !== null && (
        <div className="text-center">
          {sukses ? (
            <span className="font-bold text-green-500">
              Succes: Registration Sucess
            </span>
          ) : (
            <span className="font-bold text-red-500">Error: {msg}</span>
          )}
        </div>
      )}

      <div className="flex space-x-5 justify-center">
        {sukses !== true && (
          <button
            onClick={() => handleBack()}
            type="button"
            className={`max-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            BACK
          </button>
        )}

        {sukses !== true ? (
          <button
            onClick={() => submitPostData()}
            type="button"
            className={`
         bg-green-500
         max-w-[160px] px-10 py-2 rounded-lg text-white font-bold   w-full text-center`}
          >
            {loading ? "Loading..." : "SUBMIT"}
          </button>
        ) : (
        //   <button
        //     onClick={() => props?.ok()}
        //     type="button"
        //     className={`
        // bg-green-500
        // max-w-[160px] px-10 py-2 rounded-lg text-white font-bold   w-full text-center`}
        //   >
        //     OK
        //   </button>
        <></>
        )}
      </div>
    </div>
  );
};

export default FormSummary;
